import React, { useEffect, useState } from "react";
import Timeslots from "./components/Timeslots";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Dates from "./components/Dates";
import { MONTHS } from "../../util/date.util";
import { generateLongUniqueId } from "../../util/general.util";

import Bookings from "./components/bookings/Bookings";
import GroupBookings from "./components/groupBookings/GroupBookings";
import Trips from "./components/trips/Trips";

import axios from 'axios';
import context from "../../context";
import { Labels, optimizeSelectedBookingsPath, optimizeSelectedBookingsPath2 } from "../../util/map.util";

const GroupingCenter = () => {

    const [selectedDate,  setSelectedDate] = useState({
        month: new Date().getMonth(),
        date: 'All',
        year: new Date().getFullYear()
    });
    const [selectedTimeslot, setSelectedTimeslot] = useState('All');

    const [groupingState, setGroupingState] = useState({
        bookings:[],
        trips:[],
        selectedBookings:[],
        selectedTripBookings: [],
        selectedBookingsPath:[],
        stations: []
    })

    const fetchBookings = () =>{
        axios.get(`${context.SERVER_URL}/api/bookings?dateFilter=${JSON.stringify(selectedDate)}&timeslotFilter=${selectedTimeslot}&pathTypeFilter=connecting`)
        .then((res)=>{
            const { bookings } = res.data;
            setGroupingState((prevState)=>{ 
                return{
                    ...prevState, 
                    bookings: bookings
                } 
            });
    
        }).catch((err)=>{
            console.log(err)
        });    
    }

    const getTripBookings = (trip) => {
        const bookings = trip.path.filter(point => point.trip._id == trip._id); 
        const uniqueBookings = [...new Set(bookings.map(booking => JSON.stringify(booking)))].map(booking => JSON.parse(booking));
        return uniqueBookings;
    }

    const fetchTrips = () => {
        axios.get(`${context.SERVER_URL}/api/trips?dateFilter=${JSON.stringify(selectedDate)}&timeslotFilter=${selectedTimeslot}&pathTypeFilter=connecting`)
        .then((res)=>{   

            const { trips } = res.data; 
            let _trips = trips.map((trip)=>({
                ...trip,
                bookings: getTripBookings(trip)
            }))
            setGroupingState((prevState) =>{
                return{
                    ...prevState,
                    trips: _trips
                }
            });

        }).catch((error)=>{
            console.log(error);

        })
    }

    const fetchStations = () => {
        axios.get(`${context.SERVER_URL}/api/stations`)
        .then((res)=>{   

            const { stations } = res.data; 
            setGroupingState((prevState) =>{
                return{
                    ...prevState,
                    stations: stations
                }
            });

        }).catch((error)=>{
            console.log(error);

        })
    }

    const onBookingMarkerClicked = (booking) =>{
        //check if booking is in selectedbookings
        let _booking = groupingState.selectedBookings.filter((selectedBooking) => selectedBooking._id == booking._id)
        let selectedBookingsPath = [];
        let selectedBookings = [];

        if(_booking[0])
        {
            //booking already exists remove it
            // if selected bookings ever gets to a large number, use a hash instead
            selectedBookings = groupingState.selectedBookings.filter((selectedBooking)=>selectedBooking._id != booking._id);            
            selectedBookingsPath = groupingState.selectedBookingsPath.filter((point)=>(point.booking._id != booking._id))
        }
        else
        {
            selectedBookings = [...groupingState.selectedBookings, booking];
            selectedBookingsPath = [
                ...groupingState.selectedBookingsPath,
                ...booking.path.filter((point) => point.trip == null )
                    .map((point)=>{
                    return{
                        _id: point._id,
                        transitCapacity: point.transitCapacity,
                        capacity: point.capacity,
                        label: null,
                        booking,
                        type: point.pointType,
                        stopType: point.stopType,
                        new: point.new,
                        latitude: point.location.coordinates[1],
                        longitude: point.location.coordinates[0],
                        address: point.address,
                        order: point.order,
                        transitOrder: point.transitOrder
                    }
                })
            ];
        }

        if (groupingState.selectedTripBookings.length != 0  && selectedBookingsPath.length != 0 && selectedBookings.length == 0)
        {
            //if there is a trip selected, and all bookings removed, do no optimize 
            let trip = groupingState.trips.filter((trip) => trip._id == selectedBookingsPath[0].booking.trip)[0];

            if(trip)
            {
                selectedBookingsPath = trip.path.map((point)=>{
                    const { _id, booking, pointType, order, transitOrder, capacity, transitCapacity, address, trip, stopType, } = point;
                    const _pointBooking = groupingState.selectedTripBookings.filter((pointBooking)=> pointBooking._id == booking )[0]
                    return {
                        _id,
                        capacity,
                        order,
                        transitOrder,
                        transitCapacity,
                        label: null,
                        booking: _pointBooking,
                        type: pointType,
                        new: point.new,
                        stopType,
                        trip,
                        address,
                        latitude: point.location.coordinates[1],
                        longitude: point.location.coordinates[0]
                    }
                })
            }

        } else
        {
            //selectedBookingsPath = optimizeSelectedBookingsPath(selectedBookingsPath);
            selectedBookingsPath = optimizeSelectedBookingsPath2(selectedBookingsPath); 
        }

        // create booking transit capacity

        selectedBookingsPath = selectedBookingsPath.map((point,index)=>{
            let points = selectedBookingsPath.slice(0, index + 1).filter((point)=> point.stopType != "station");
            let transitCapacity = points.reduce((acc, point)=>{
                point.capacity.forEach((product)=>{
                    let foundProductIndex = acc.findIndex((accProduct)=> accProduct.description == product.description);
                    if (foundProductIndex == -1){
                        acc.push(product);
                    }
                    else{
                        switch(point.type){
                            case "location":
                                acc[foundProductIndex].quantity += product.quantity;
                                break;
                            case "destination":
                                acc[foundProductIndex].quantity += product.quantity;
                                break;
                            case "pass":
                                break;
                                default:
                        }
                    }

                });
                return acc;
            },[]);

            return {
                ...point,
                label: Labels[index],
                transitCapacity:[
                    ...transitCapacity
                ]
            };
        })

        setGroupingState((prevState)=>{
            return{
                ...prevState,
                selectedBookings,
                selectedBookingsPath
            }
        })
    }

    const onTripMarkerClicked = (trip) =>{

        let _selectedTripBookings = [];
        let _selectedBookingsPath = []

        const { selectedTripBookings, selectedBookingsPath } = groupingState;

        if (selectedTripBookings.length == 0)
        {
            //add selectedTripBookings and path to selectedBookings 

            _selectedTripBookings = trip.bookings;
            let _selectedTripBookingsPath = trip.path.map((point)=>{
                const { _id, booking, pointType, order, transitOrder, capacity, transitCapacity, address, trip, stopType } = point;
                return {
                    _id,
                    capacity,
                    order,
                    transitOrder,
                    transitCapacity,
                    label: null,
                    booking,
                    type: pointType,
                    stopType,
                    trip,
                    address,
                    latitude: point.location.coordinates[1],
                    longitude: point.location.coordinates[0]
                }
            });

            _selectedBookingsPath = [ ..._selectedTripBookingsPath, ...selectedBookingsPath ]

        }
        else if(selectedTripBookings[0].trip != trip._id)
        {
            // replace trip bookings
            _selectedTripBookings = trip.bookings;
            let _selectedTripBookingsPath = trip.path.map((point)=>{
                const { _id, booking, pointType, order, transitOrder, capacity, transitCapacity, address, trip, stopType } = point;
                const _pointBooking = _selectedTripBookings.filter((pointBooking)=> pointBooking._id == booking )[0]

                return {
                    _id,
                    capacity,
                    order,
                    transitOrder,
                    transitCapacity,
                    label: null,
                    booking:_pointBooking,
                    type: pointType,
                    stopType,
                    trip,
                    address,
                    latitude: point.location.coordinates[1],
                    longitude: point.location.coordinates[0]
                }
            });

            // filter old selected trip bookings and add any remaining bookings
            const _filteredSelectedBookingsPath = selectedBookingsPath.filter((point)=> point.trip != selectedTripBookings[0].trip);

            _selectedBookingsPath = [..._selectedTripBookingsPath, ..._filteredSelectedBookingsPath];

        }
        else if (selectedTripBookings[0].trip == trip._id )
        {
            // this is a toggle, remove bookings and filter out its path
            _selectedBookingsPath = selectedBookingsPath.filter((point)=> point.trip != selectedTripBookings[0].trip);

        }


        // if there are selected bookings already, optimize
        if(selectedBookingsPath.length != 0)
        {
            //_selectedBookingsPath = optimizeSelectedBookingsPath(_selectedBookingsPath)
            _selectedBookingsPath = optimizeSelectedBookingsPath2(_selectedBookingsPath); 
        }

        // calculate capacity
        _selectedBookingsPath = _selectedBookingsPath.map((point,index)=>{
            let points = _selectedBookingsPath.slice(0, index + 1).filter((point)=> point.stopType != "station");
            let transitCapacity = points.reduce((acc, point)=>{
                point.capacity.forEach((product)=>{
                    let foundProductIndex = acc.findIndex((accProduct)=> accProduct.description == product.description);
                    if (foundProductIndex == -1){
                        acc.push(product);
                    }
                    else{
                        switch(point.type){
                            case "location":
                                acc[foundProductIndex].quantity += product.quantity;
                                break;
                            case "destination":
                                acc[foundProductIndex].quantity += product.quantity;
                                break;
                            case "pass":
                                break;
                                default:
                        }
                    }

                });
                return acc;
            },[]);

            return {
                ...point,
                label: Labels[index],
                transitCapacity:[
                    ...transitCapacity
                ]
            }
        })

        setGroupingState((prevState)=>{
            return{
                ...prevState,
                selectedTripBookings: _selectedTripBookings,
                selectedBookingsPath: _selectedBookingsPath
            }
        })

    }

    const onGroupBookings = () => {

        const { selectedBookings, selectedBookingsPath } = groupingState;

        let _path = [];
        let transitOrder = 0;

        selectedBookingsPath.forEach((point)=>{
            const  { transitCapacity, booking} = point;

            _path.push({
                _id: point._id,
                booking: booking._id,
                transitOrder: transitOrder ++ ,
                transitCapacity,
                stopType: point.stopType,
                type: point.type,
                stopType: point.stopType,
                new: point.new,
                latitude: point.latitude,
                longitude: point.longitude,
                address: point.address,
                order: point.order
            })
        })
        const { date, timeslot } = selectedBookingsPath[0].booking;

        axios.post(`${context.SERVER_URL}/api/trips`,
        {
            data:
            {
                date,
                timeslot,
                path: _path,
            }

        }).then(async (response)=>{   

            // remove bookings and path
            setGroupingState((prevState)=>{
                return {
                    ...prevState,
                    selectedBookings: [],
                    selectedBookingsPath: [],
                }
            });

            // fetch trips and bookings
            fetchBookings()
            fetchTrips();
        }).catch((error)=>{
            console.log(error);

        })
    }

    const onAddBookingsToGroup = () => {
        const { selectedBookings, selectedBookingsPath, selectedTripBookings } = groupingState;
        const allSelectedBookings = [...selectedBookings, ...selectedTripBookings];
        const _tripBookings = allSelectedBookings.map((selectedBooking)=> selectedBooking._id);
        let transitOrder = 0;
        const path = selectedBookingsPath.map((point)=>{
            return({
                _id: point._id,
                transitOrder: transitOrder ++ ,
                transitCapacity: point.transitCapacity
            })
        })
        const { date, timeslot } = selectedBookingsPath[0].booking;
        const tripId = selectedTripBookings[0].trip

        axios.put(`${context.SERVER_URL}/api/trips/${tripId}/addToTrip`,
        {
            data:
            {
                date,
                timeslot,
                path,
                bookings: selectedBookings.map((booking)=> booking._id)
            }

        }).then(async (response)=>{   

            // remove bookings and path
            setGroupingState((prevState)=>{
                return {
                    ...prevState,
                    selectedBookings: [],
                    selectedBookingsPath: [],
                    selectedTripBookings: []
                }
            });

            // fetch trips and bookings
            fetchBookings()
            fetchTrips();
        }).catch((error)=>{
            console.log(error);

        })
    }

    const onReorderGroupBookings = () =>{
        const { selectedBookingsPath, selectedTripBookings } = groupingState;
        const tripId = selectedTripBookings[0].trip
        const { date, timeslot } = selectedBookingsPath[0].booking;

        let transitOrder = 0;
        const path = selectedBookingsPath.map((point)=>{
            return({
                _id: point._id,
                transitOrder: transitOrder ++ ,
                transitCapacity: point.transitCapacity
            })
        })

        axios.put(`${context.SERVER_URL}/api/trips/${tripId}/reorderTrip`,
        {
            data:
            {
                date,
                timeslot,
                path,
            }

        }).then(async (response)=>{   

            // remove bookings and path
            setGroupingState((prevState)=>{
                return {
                    ...prevState,
                    selectedBookings: [],
                    selectedBookingsPath: [],
                    selectedTripBookings: []
                }
            });

            // fetch trips and bookings
            fetchBookings()
            fetchTrips();
        }).catch((error)=>{
            console.log(error);

        })
    }
    const onRemoveBookingFromGroup = (booking) =>{

       const tripId = booking.trip
        const removedBooking = booking._id
        const { selectedBookingsPath } = groupingState;

        let _selectedBookingsPath = selectedBookingsPath.filter((point)=> point.booking._id != booking._id );

        _selectedBookingsPath = optimizeSelectedBookingsPath2(_selectedBookingsPath);
        const { date, timeslot } = _selectedBookingsPath[0].booking;

        let transitOrder = 0;      
        let _path = [];
        _selectedBookingsPath.forEach((point, index)=>{
            let points = _selectedBookingsPath.slice(0, index + 1).filter((point)=> point.stopType != "station");
            let transitCapacity = points.reduce((acc, point)=>{
                point.capacity.forEach((product)=>{
                    let foundProductIndex = acc.findIndex((accProduct)=> accProduct.description == product.description);
                    if (foundProductIndex == -1){
                        acc.push(product);
                    }
                    else{
                        switch(point.type){
                            case "location":
                                acc[foundProductIndex].quantity += product.quantity;
                                break;
                            case "destination":
                                acc[foundProductIndex].quantity += product.quantity;
                                break;
                            case "pass":
                                break;
                                default:
                        }
                    }

                });
                return acc;
            },[]);
            _path.push({
                _id: point._id,
                transitOrder: transitOrder ++,
                transitCapacity:[
                    ...transitCapacity
                ]
            })
        })

        axios.put(`${context.SERVER_URL}/api/trips/${tripId}/removeFromTrip`,
        {
            data:
            {
                removedBooking,
                path: _path,
                date,
                timeslot
            }

        }).then(async (response)=>{   

            // remove bookings and path
            setGroupingState((prevState)=>{
                return {
                    ...prevState,
                    selectedBookings: [],
                    selectedBookingsPath: [],
                    selectedTripBookings: []
                }
            });

            // fetch trips and bookings
            fetchBookings()
            fetchTrips();
        }).catch((error)=>{
            console.log(error);

        })
    }

    const addStationPointToBookingPath = (pointBeforeStation, stationId, stationPointType) =>{
        const { stations, bookings } = groupingState;
        const station = stations.find((station)=> station._id == stationId);
        const bookingIndex = bookings.findIndex((booking)=> booking._id == pointBeforeStation.booking);

        if (!station || bookingIndex  == -1)
        {
            return;
        }

        const booking = bookings[bookingIndex];
        let index = booking.path.findIndex((point)=> point._id == pointBeforeStation._id);

        if (stationPointType == "pickdrop")
        {
            let stationPoints = ["destination", "location"].map((type)=>{
                return {
                    ...pointBeforeStation,
                    _id: generateLongUniqueId(),
                    new: true,
                    pointType: type,
                    stopType: "station",
                    location: station.location,
                    address: station.address,
                }
            })
            booking.path.splice(index + 1, 0, stationPoints[0], stationPoints[1]);
        }
        else if (stationPointType == "pass")
        {
            let passPoint = {
                ...pointBeforeStation,
                _id: generateLongUniqueId(),
                new: true,
                pointType: "pass",
                stopType: "station",
                location: station.location,
                address: station.address,
            }

            booking.path.splice(index + 1, 0, passPoint);
        }

        let _bookings = [...bookings];
        booking.path = booking.path.map((point, index)=>({
            ...point, 
            order: index,
            transitOrder: index
        }));

        _bookings.splice(bookingIndex, 1, booking);

        setGroupingState((prevState)=>{
            return {
                ...prevState,
                bookings: _bookings
            }
        })

    }

    useEffect(()=>{
        fetchStations()
    }, [])

    useEffect(()=>{
        fetchBookings();
        fetchTrips();

        //set selected bookings and bookings path to null

        setGroupingState((prevState)=>{
            return {
                ...prevState,
                selectedBookings: [],
                selectedBookingsPath: [],
                selectedTripBookings: []
            }
        })
    },[selectedDate, selectedTimeslot])

    const renderTabs = () =>{
        return(
            <Tabs
                style={{
                    width: 100 + "%",
                    height: 95 + "%"
                }}
            >
                <TabList>
                    <Tab>Bookings</Tab>
                    <Tab>Group Bookings</Tab>
                    <Tab>Trips</Tab>
                </TabList>
                <TabPanel
                    style={{
                        width: 100 + "%",
                        height: 100 + "%"
                    }}
                >
                    <Bookings
                        groupingState={groupingState}
                    />
                </TabPanel>
                <TabPanel
                    style={{
                        width: 100 + "%",
                        height: 100 + "%"
                    }}
                >
                    <GroupBookings
                        groupingState={groupingState}
                        selectedDate={selectedDate}
                        selectedTimeslot={selectedTimeslot}
                        onBookingMarkerClicked ={onBookingMarkerClicked}
                        onTripMarkerClicked = {onTripMarkerClicked}
                        onGroupBookings={onGroupBookings}
                        onAddBookingsToGroup={onAddBookingsToGroup}
                        onRemoveBookingFromGroup = {onRemoveBookingFromGroup}
                        onOrderSelectedBookingsPath={onOrderSelectedBookingsPath}
                        onReorderGroupBookings ={onReorderGroupBookings}
                        openInGoogleMaps={openInGoogleMaps}
                        addStationPointToBookingPath={addStationPointToBookingPath}
                    />
                </TabPanel>
                <TabPanel>
                    <Trips
                        selectedDate={selectedDate}
                    />
                </TabPanel>
            </Tabs>
        )
    }

    const onChangeYear = (action) => {
        if (action === "decrease")
        {
            setSelectedDate({...selectedDate, year: selectedDate.year - 1 })

        } 
        else if(action === "increase")
        {
            setSelectedDate({...selectedDate, year: selectedDate.year + 1 })
        } 

    }
    
    const onChangeMonth = (action) =>{
        if (action === "decrease" && (selectedDate.month - 1) >= 0)
        {
            setSelectedDate({...selectedDate, month: selectedDate.month - 1 })
        } 
        else if(action === "increase" && (selectedDate.month + 1) <= 11)
        {
            setSelectedDate({...selectedDate, month: selectedDate.month + 1 })
        } 
    }

    const onOrderSelectedBookingsPath =  ({oldIndex, newIndex}) =>{
        let _selectedBookingsPath = [...groupingState.selectedBookingsPath];
        //remove element
        let _point = _selectedBookingsPath.splice(oldIndex, 1);

        // add element
        _selectedBookingsPath.splice(newIndex,0,_point[0]);

        let transitCapacity = 0;
        const selectedBookingsPath = _selectedBookingsPath.map((point,index)=>{
            if (point.type == "location")
            {
                transitCapacity += point.capacity
            }
            else
            {
                transitCapacity -= point.capacity
            }

            point.transitCapacity = transitCapacity;
            point.label = Labels[index]
            return point;
        })

        // we need to recalculate capacity here

        setGroupingState((prevState)=>{

            return {
                ...prevState,
                selectedBookingsPath
            }
        })

    }

    const openInGoogleMaps = (path) =>{

        let origin = '';
        let waypoints = []
        let destination = '';
        path.forEach((point, index)=>{

            const {booking, type} = point;
            const [latitude, longitude] =  booking[type].coordinates;

            if (index == 0)
            {
                origin = `${latitude},${longitude}`

            }
            else if(index == path.length-1)
            {
                destination = `${latitude},${longitude}`

            }
            else
            {
                waypoints.push(`${latitude},${longitude}`)
            }

        })

        let link = `https://www.google.com/maps/dir/?api=1&origin=${origin}&destination=${destination}&waypoints=${waypoints.join('|')}`;
  
        window.open(link, '_blank').focus();
    }


    return(
        <div className="flex flex-row bg-gray-100 w-full h-full">
            <div className="flex flex-col bg-gray-200 p-5 border-r-2 border-gray-500 h-full">
                <div className="flex flex-col w-full h-1/6">
                    <div className="flex flex-row mb-3 h-fit">
                        <div className="flex flex-row w-full justify-start items-center">
                            <label className="mr-5">Year:</label>
                            <div>{selectedDate.year}</div>
                        </div>
                        <div className="flex flex-row">
                            <button 
                                className="px-3 py-1 bg-gray-300 border-2 border-gray-500 text-gray-500 justify-center intems-center rounded-lg mr-3"
                                onClick={()=>onChangeYear('decrease')}
                            >{'<'}</button>
                            <button 
                                className="px-3 py 1 bg-gray-300 border-2 border-gray-500 text-gray-500 justify-center intems-center rounded-lg"
                                onClick={()=>onChangeYear('increase')}
                            >{'>'}</button>
                        </div>
                    </div>
                    <div className="flex flex-row mb-3 h-fit">
                        <div className="flex flex-row w-full justify-start items-center">
                            <label className="mr-5">Month:</label>
                            <div>{MONTHS[selectedDate.month]}</div>
                        </div>
                        <div className="flex flex-row">
                            <button 
                                className="px-3 py-1 bg-gray-300 border-2 border-gray-500 text-gray-500 justify-center intems-center rounded-lg mr-3"
                                onClick={()=>onChangeMonth('decrease')}
                            >{'<'}</button>
                            <button 
                                className="px-3 py 1 bg-gray-300 border-2 border-gray-500 text-gray-500 justify-center intems-center rounded-lg"
                                onClick={()=>onChangeMonth('increase')}
                            >{'>'}</button>
                        </div>
                    </div>
                </div>
                
                <div className="flex flex-row justify-around h-4/5">
                    {<Timeslots 
                        selectedTimeslot={selectedTimeslot}
                        setSelectedTimeslot={setSelectedTimeslot}
                    />}
                    {<Dates 
                        selectedDate={selectedDate}
                        setSelectedDate={setSelectedDate}
                    />}
                </div>
            </div>
            <div className="bg-gray-200 border-r-2 border-gray-500 w-full h-full">
                {
                    renderTabs()
                }
            </div>
        </div> 
    )
}
export default GroupingCenter;