import React from 'react';
import Map from './Map';

const GroupBookings = ({
    groupingState,
    setGroupingState,
    selectedTimeslot,
    selectedDate,
    onBookingMarkerClicked,
    onTripMarkerClicked,
    onGroupBookings,
    onAddBookingsToGroup,
    onReorderGroupBookings,
    onRemoveBookingFromGroup,
    onOrderSelectedBookingsPath,
    openInGoogleMaps,
    addStationPointToBookingPath
}) =>{

    return(
        <div className='w-full h-full'>
            <div className="grid grid-cols-12 gap-4 bg-gray-100 w-full h-full">
                <div className="col-span-11 bg-gray-200 md:col-span-10 h-full">
                    <Map 
                        groupingState = {groupingState}
                        onBookingMarkerClicked = {onBookingMarkerClicked}
                        onGroupBookings = {onGroupBookings}
                        onOrderSelectedBookingsPath={onOrderSelectedBookingsPath}
                        onTripMarkerClicked = {onTripMarkerClicked}
                        onAddBookingsToGroup ={onAddBookingsToGroup}
                        onReorderGroupBookings = {onReorderGroupBookings}
                        onRemoveBookingFromGroup = {onRemoveBookingFromGroup}
                        openInGoogleMaps={openInGoogleMaps}
                        addStationPointToBookingPath = {addStationPointToBookingPath}
                    />
                </div>
                <div className="col-span-1 bg-red-200 p-5 md:col-span-2 border-r-2 border-gray-500 h-full">
                    <h1>placeholder</h1>
                </div>

            </div>

        </div>
    )
}

export default GroupBookings;